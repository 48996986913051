import { http } from "@/net/http";
import { useUserStore } from "@/stores/user";
import { buryingPointData } from "@/utils/buryingPointData";
import { encodeToBase64 } from "@/utils/commonUtils";
import { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { uuid } from "vue3-uuid";

/**
 * 用户信息数据
 */
export type CurrentUserInfo = {
  id: string;
  headImg: string;
  name: string;
  linkardId: string;
  path: string;
  phone: string;
  pathAllowChanges: boolean;
  usedCnt: number;
  remainingCnt: number;
  totalCnt: number;
};

/**
 * 媒体列表
 */
export type SocialLinksListInfo = {
  icon: string;
  name: string;
  url: string;
  isTop: boolean;
};

/**
 * 用户登录数据
 */
export type CurrentUserLoginInfo = {
  token: string;
  sdkToken: {
    token: string;
    expiresAt: number;
  };
  userId: string;
  streamId: string;
};

/**
 * 当前联卡信息
 */
export type CurrentLKInfo = {
  id: string;
  path: string;
  icon: string;
  name: string;
  scaleValid: boolean;
  tags: string[];
  operation: {
    phone: string;
  };
  newTop: false;
  style: string;
  npc: {
    avatar: string;
    filepath: string;
    mouthShape: number;
    background: string;
    npcName: string;
    voice: string;
    welcomeMsg: string;
    greetingMsg: {
      text: string;
      playAudio: boolean;
      image: {
        url: string;
        thumbnail: string;
      };
      video: {
        url: string;
        thumbnail: string;
      };
    };
  };
  isFavorite: false;
  isOwn: true;
  cabinetValid: false;
  spaceValid: false;
  messageValid: true;
  sourceType: number;
  scaleIconText: string;
};

/**
 * 微信信息数据
 */
export type WxJsapiTicketInfo = {
  nonceStr: string;
  timestamp: string;
  url: string;
  signature: string;
};

/**
 * 上报请求参数
 */
export type BizReportArgs = {
  bizId: string;
  status: number;
  errorMsg: string;
  elapse: number;
};

/**
 * 上报请求参数
 */
export type BizStageReportArgs = {
  stage: string; //关键节点名称，前端自定定义，后端存库+查询的时候使用
  bizId: string; //每次会话的唯一id，跟AIComputeReq中的callback保持一致
  status: number; //0：成功，其他前端自行约定
  errorMsg: string; //成功：success，其他自行填写
  npcId: string; //对话的npcId
};

/**
 * 音频sdk登录
 */
export type AudioSdkLoginArgs = {
  uid: string;
  package: string;
  token: string;
};

/**
 * 发送消息
 */
export type SendMessageArgs = {
  linkardId: string;
  message: string;
};

/**
 * 点击媒体
 */
export type ClickLinkArgs = {
  linkardId: string;
  url: string;
};

/**
 * 联卡Item
 */
export type LinkardItem = {
  id: string;
  url: string;
  name: string;
  showName: string;
  avatar: string;
  npcAvatar: string;
  tags: string[];
  QRImgUrl: string;
};

/**
 * 专题list
 */
export type SpecialTopicItem = {
  name: string;
  url: string;
  linkardList: LinkardItem[];
};

/**
 * 卡柜数据
 */
export type CabinetInfoResponse = {
  cabinetName: string;
  specialTopicList: SpecialTopicItem[];
};

/**
 * 留言Item
 */
export type LeaveMessageItem = {
  userId: string;
  headImg: string;
  name: string;
  level: number;
  message: string;
  path: string;
  cabinetValid: boolean;
  createTime: number;
  messageTime: string;
  versionIcon: string;
  canGotoCabinet: boolean;
};

/**
 * 联卡留言查询
 */
export type LeaveMessageResponse = {
  pageSize: number;
  pageNum: number;
  total: number;
  list: LeaveMessageItem[];
};

/**
 * 联卡收藏列表
 */
export type FavoritesListResponse = {
  pageSize: number;
  pageNum: number;
  total: number;
  list: LinkardItem[];
};

/**
 * 联卡收藏列表
 */
export type GenerateSdkTokenResponse = {
  token: string;
  expiresAt: number;
};

/**
 * sdk返回数据
 */
export type AudioSdkLoginResponse = {
  uid: string;
  loginId: string;
  token: string;
  appConfig: {
    services: string[];
    characterList: [];
    backgroundList: [];
  };
  streamId: string;
};

/**
 * 量表答案
 */
export type ScaleAnswerItem = {
  option: string;
  content: string;
};

/**
 * 量表题目
 */
export type ScaleQuestionItem = {
  title: string;
  type: string;
  sort: number;
  answer: ScaleAnswerItem[];
};

/**
 * 测评结果Item
 */
export type ScaleReportListItem = {
  id: string;
  title: string;
  createTime: number;
  createDate?: string;
};

/**
 * 测评结果列表
 */
export type ScaleReportListResponse = {
  pageSize: number;
  pageNum: number;
  total: number;
  list: ScaleReportListItem[];
};

/**
 * 测评详情Item
 */
export type ScaleDetailItem = {
  group: string;
  result: string;
  analysis: string;
  advice: string;
  extra: string;
  score: {
    total: string;
    score: string;
    critical: string;
  };
};

/**
 * 测评性情
 */
export type ScaleDetailResponse = {
  id: string;
  title: string;
  resultIntro: string;
  createTime: number;
  createDate?: string;
  extraTitle: string;
  extraQRCode: string;
  type: string;
  reportList: ScaleDetailItem[];
};

class LinkardNet {
  ///////////  ///////////  ///////////  ///////////  ///////////  ///////////

  /**
   * 获取卡柜数据
   * @returns
   */
  getCabinetInfoByUser = async (
    lookedUserId: string
  ): Promise<CabinetInfoResponse> => {
    return http.get<CabinetInfoResponse>(
      `/v4/home/linkard/cabinet/infoByUser?lookedUserId=${lookedUserId}`
    );
  };

  /**
   * 获取卡柜数据
   * @returns
   */
  cabinetInfoById = async (cabinetId: string): Promise<CabinetInfoResponse> => {
    return http.get<CabinetInfoResponse>(
      `/v4/home/linkard/cabinet/infoById?cabinetId=${cabinetId}`
    );
  };

  /**
   * 获取卡柜数据
   * @returns
   */
  getMessageBoardList = async (
    pageNum: number,
    pageSize: number,
    linkardId: string
  ): Promise<LeaveMessageResponse> => {
    return http.get<LeaveMessageResponse>(
      `/v4/home/linkard/leaveMessageList?pageNum=${pageNum}&pageSize=${pageSize}&linkardId=${linkardId}`
    );
  };

  /**
   * 获取卡柜数据
   * @returns
   */
  getCabinetInfo = async (linkardId: string): Promise<CabinetInfoResponse> => {
    return http.get<CabinetInfoResponse>(
      `/v4/home/linkard/cabinet/info?linkardId=${linkardId}`
    );
  };

  /**
   * 联卡收藏列表
   * @returns
   */
  favoritesList = async (
    pageNum: number,
    pageSize: number,
    search: string,
    type: string
  ): Promise<FavoritesListResponse> => {
    return http.get<FavoritesListResponse>(
      `/v4/home/favorites/list?search=${search}&pageNum=${pageNum}&pageSize=${pageSize}&type=${type}`
    );
  };

  /**
   * 我的主页卡片列表
   * @returns
   */
  myHomeLinkardList = async (
    pageNum: number,
    pageSize: number,
    search: string
  ): Promise<FavoritesListResponse> => {
    return http.get<FavoritesListResponse>(
      `/v4/home/linkard/myHomeLinkardList?search=${search}&pageNum=${pageNum}&pageSize=${pageSize}`
    );
  };

  /**
   * 发送留言
   * @param arg
   * @returns
   */
  sendMessageBoard = async (arg: SendMessageArgs): Promise<void> => {
    return http.post("/v4/home/linkard/leaveMessage", arg);
  };

  /**
   * 上报日志
   * @param arg
   * @returns
   */
  bizResultReport = async (
    arg: BizReportArgs,
    token: string
  ): Promise<void> => {
    const headers = new AxiosHeaders();
    headers.set("T-Validate-Token", token);
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post(
      process.env.VUE_APP_SDK_API + "/v1/statistic/bizResultReport",
      arg,
      config
    );
  };

  /**
   * 前端日志埋点
   * @param arg
   * @returns
   */
  bizStageReport = async (
    arg: BizStageReportArgs,
    token: string
  ): Promise<void> => {
    const headers = new AxiosHeaders();
    headers.set("T-Validate-Token", token);
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post(
      process.env.VUE_APP_SDK_API + "/v1/statistic/bizStageReport",
      arg,
      config
    );
  };

  /**
   * 收藏联卡
   * @returns
   */
  addById = async (linkardId: string): Promise<void> => {
    return http.get<void>(`/v2/home/favorites/addById?id=${linkardId}`);
  };

  /**
   * 取消收藏联卡
   * @returns
   */
  removeById = async (linkardId: string): Promise<void> => {
    return http.get<void>(`/v2/home/favorites/removeById?id=${linkardId}`);
  };

  /**
   * 取消收藏联卡
   * @returns
   */
  isFavorite = async (linkardId: string): Promise<boolean> => {
    return http.get<boolean>(`/v2/home/favorites/isFavorite?id=${linkardId}`);
  };

  /**
   * 登录
   * @returns
   */
  login = async (
    code: string,
    type: number,
    P_code: string,
    P_data: string
  ): Promise<CurrentUserLoginInfo> => {
    if (P_code && P_code != undefined && P_code != 'undefined') {
      return http.post(
        `/v3/user/login?code=${code}&type=${type}&P_code=${P_code}&P_data=${P_data}`
      );
    } else {
      return http.post(`/v3/user/login?code=${code}&type=${type}`);
    }
  };

  /**
   * 公众号重定向
   * @returns
   */
  redirectUrl = async (): Promise<void> => {
    return http.get<void>(`/wechat/mp/redirectUrl`);
  };

  /**
   * 获取微信用户详情
   * @returns
   */
  getWxMpUserInfo = async (): Promise<void> => {
    return http.get<void>(`/v2/user/getWxMpUserInfo`);
  };

  /**
   * 获取当前详情
   * @returns
   */
  getCurrentDetail = async (): Promise<void> => {
    return http.get(`/v4/home/linkard/getCurrent`);
  };

  /**
   * 登录
   * @returns
   */
  generateSdkToken = async (): Promise<GenerateSdkTokenResponse> => {
    return http.post<GenerateSdkTokenResponse>(`/v3/user/generateSdkToken`);
  };

  /**
   * 上报日志
   * @param arg
   * @returns
   */
  audioSdkLogin = async (
    arg: AudioSdkLoginArgs,
    token: string
  ): Promise<AudioSdkLoginResponse> => {
    const headers = new AxiosHeaders();
    headers.set("T-Validate-Token", token);
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post(
      process.env.VUE_APP_SDK_API + "/v1/auth/login",
      arg,
      config
    );
  };

  /**
   * 获取连卡信息
   * @returns
   */
  getInfo = async (path: string): Promise<CurrentLKInfo> => {
    return http.get<CurrentLKInfo>(`/v4/home/linkard/getInfo?path=${path}`);
  };

  /**
   * 获取用户信息
   * @returns
   */
  getUserDetail = async (): Promise<CurrentUserInfo> => {
    return http.get<CurrentUserInfo>(`/v4/user/getUserDetail`);
  };

  /**
   * 获取微信信息
   * @returns
   */
  getWxJsapiTicketSignature = async (
    url: string,
    wxName: string
  ): Promise<WxJsapiTicketInfo> => {
    return http.get<WxJsapiTicketInfo>(
      process.env.VUE_APP_WX_URL +
        `/wechat/mp/getWxJsapiTicketSignature?url=${url}&wxName=${wxName}`
    );
  };

  /**
   * 点击媒体
   * @returns
   */
  clickLink = async (args: ClickLinkArgs): Promise<void> => {
    return http.post(`/v2/home/socialLinks/clickLink`, args);
  };

  /**
   * 获取联卡聚合列表
   * @returns
   */
  socialLinksList = async (
    linkardId: string
  ): Promise<SocialLinksListInfo[]> => {
    return http.get<SocialLinksListInfo[]>(
      `/v2/home/socialLinks/list?id=${linkardId}`
    );
  };

  /**
   * 获取图片或者视频缩略图对应的资源
   * @returns
   */
  getOriginalMediaUrl = async (thumbnailUrl: string): Promise<string> => {
    return http.get<string>(
      `/v4/home/linkard/getMediaUrl?thumbnailUrl=${thumbnailUrl}`
    );
  };

  /**
   * 对话埋点
   * @returns
   */
  buryingPointChatDataReport = async (
    linkardId: string,
    chatRole: string,
    chatModle: string,
    chatContent: string,
    urlPath: string,
    systemInfo: any
  ): Promise<any> => {
    const propertiesInfo = {
      url_path: urlPath,
      ...systemInfo,
    };

    const data = {
      event_id: uuid.v4(),
      event_name: "linkard_H5_chat",
      event_data: {
        user_id: sessionStorage.getItem("userId"),
        linkard_id: linkardId,
        linkard_name: buryingPointData.linkardName,
        chat_id: buryingPointData.chatId,
        chat_role: chatRole,
        chat_modle: chatModle,
        chat_content: chatContent,
        round_id: buryingPointData.chatRequestId,
      },
      properties: propertiesInfo,
    };

    return http.post(`/v2/event/linkardReport`, {
      data: encodeToBase64(JSON.stringify(data)),
    });
  };

  /**
   * 页面埋点
   * @returns
   */
  buryingPointPageDataReport = async (
    linkardId: string,
    urlPath: string,
    systemInfo: any
  ): Promise<any> => {
    const propertiesInfo = {
      url_path: urlPath,
      ...systemInfo,
    };

    let eventData = {};

    if (
      sessionStorage.getItem("userId") &&
      sessionStorage.getItem("userId") != undefined
    ) {
      eventData = {
        user_id: sessionStorage.getItem("userId"),
        linkard_id: linkardId,
        linkard_name: buryingPointData.linkardName,
      };
    } else {
      eventData = {
        visitor_id: uuid.v4(),
        linkard_id: linkardId,
        linkard_name: buryingPointData.linkardName,
      };
    }

    const data = {
      event_id: uuid.v4(),
      event_name: "linkard_H5_enter",
      event_data: eventData,
      properties: propertiesInfo,
    };

    return http.post(`/v2/event/linkardReport`, {
      data: encodeToBase64(JSON.stringify(data)),
    });
  };

  /**
   * 测评量表-列表
   * @returns
   */
  getScaleList = async (linkardId: string, scaleIds: string): Promise<any> => {
    let url;
    if (scaleIds) {
      // const scaleIdList = JSON.parse(scaleIds);
      // const tempScaleIds = scaleIdList.join(",");
      url = `/v4/home/linkard/getScaleList?linkardId=${linkardId}&scaleIds=${scaleIds}&pageNum=${1}&pageSize=100`;
    } else {
      url = `/v4/home/linkard/getScaleList?linkardId=${linkardId}&openStatus=${1}&pageNum=${1}&pageSize=100`;
    }
    return http.get<any>(url);
  };

  /**
   * 测评量表-查询题目
   * @returns
   */
  scaleQuestion = async (
    linkardId: string,
    scaleId: string
  ): Promise<ScaleQuestionItem[]> => {
    return http.get<ScaleQuestionItem[]>(
      `/v4/home/linkard/getScaleQuestion?scaleId=${scaleId}&linkardId=${linkardId}`
    );
  };

  /**
   * 测评量表-提交答案
   * @returns
   */
  submitScaleAnswer = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/submitScaleResult`, args);
  };

  /**
   * 测评量表-生成报告
   * @returns
   */
  createScaleReport = async (args: any): Promise<string> => {
    return http.post(`/v4/home/linkard/createScaleReport`, args);
  };

  /**
   * 我的测评结果列表
   * @returns
   */
  getScaleReportList = async (
    linkardId: string,
    pageNum: number,
    pageSize: number
  ): Promise<ScaleReportListResponse> => {
    return http.get<ScaleReportListResponse>(
      `/v4/home/linkard/getScaleReportList?linkardId=${linkardId}&pageNum=${pageNum}&pageSize=${pageSize}`
    );
  };

  /**
   * 测评结果详情
   * @returns
   */
  getScaleReportDetail = async (
    linkardId: string,
    reportId: string
  ): Promise<ScaleDetailResponse> => {
    return http.get<ScaleDetailResponse>(
      `/v4/home/linkard/getScaleReportDetail?reportId=${reportId}&linkardId=${linkardId}`
    );
  };
}

export const linkardNet = new LinkardNet();
